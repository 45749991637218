// Welcome to Code in Framer
// Get Started: https://www.framer.com/docs/guides/
import { useEffect, useState } from "react"

// import Example from "https://framer.com/m/framer/Example.js@^1.0.0"
// import LaunchDarkly, {
//     asyncWithLDProvider,
//     useFlags,
//     useLDClient,
// } from "launchdarkly-react-client-sdk"
import * as LDClient from "launchdarkly-js-client-sdk"

const clientSideID = "63d8fb40d3744f127a5ccbda"

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/docs/guides/auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function LDFeatureFlagTest(props) {
    const [ldClient, setLdClient] = useState()
    const [flagVal, setFlagVal] = useState(null)
    // This is a React component containing an Example component
    // - Replace <Example /> with your own code
    // - Find inspiration: https://www.framer.com/developers/
    // const flags = useFlags()
    // const ldClient = useLDClient()
    // const LDProvider = await asyncWithLDProvider({
    //     clientSideID,
    //     context: {
    //         kind: "user",
    //         key: "user-key-123abc",
    //         name: "AlexSharp",
    //         email: "ajsharp@gmail.com",
    //     },
    // })

    useEffect(() => {
        const client = LDClient.initialize(clientSideID, {
            kind: "user",
            key: "user-345",
        })
        client.on("ready", () => {
            setLdClient(client)
            console.log("ld client ready")
            const flagVal = client.variation("test-flag", false)
            console.log("TEST FLAG", flagVal)
            setFlagVal(flagVal)
        })
    }, [])

    return (
        <div style={containerStyle}>
            {flagVal !== null && flagVal.toString()}
        </div>
    )
}

// Styles are written in object syntax
// Learn more: https://reactjs.org/docs/dom-elements.html#style
const containerStyle = {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
}
